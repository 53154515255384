/* Styles spécifiques aux images */
.image-container {
  max-width: 100%; /* Ajustement pour les images à largeur totale */
  display: block; /* Assure que l'image est un élément de bloc */
  margin-left: auto; /* Centrage horizontal */
  margin-right: auto; /* Centrage horizontal */
}



/* Styles pour les rectangles colorés */
.theme-link1,
.theme-link2,
.theme-link3,
.theme-link4,
.theme-link5,
.theme-link6 {
  color: black;
  padding: 15px 20px;
  display: inline-block; /* Assure que les rectangles s'affichent en ligne */
  margin: 5px; /* Ajoute un peu d'espace entre les rectangles */
  white-space: nowrap; /* Empêche les rectangles de se diviser en plusieurs lignes */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Ajoute une transition pour l'effet de survol */
  font-family: 'Roboto', sans-serif; /* Police moderne */
  font-weight: 700; /* Texte en gras */
  text-decoration: none; /* Supprime le soulignement */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ajoute une ombre subtile */
}

/* Effet de survol */
.theme-link1:hover,
.theme-link2:hover,
.theme-link3:hover,
.theme-link4:hover,
.theme-link5:hover,
.theme-link6:hover {
  transform: scale(1.1); /* Agrandit légèrement le rectangle au survol */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Augmente l'ombre au survol */
}

/* Styles spécifiques pour les différentes couleurs de fond */
.theme-link1 {
  background-color: #FFE3EE; /* Pastel rose clair */
}

.theme-link2 {
  background-color: #E7F6FF; /* Pastel bleu clair */
}

.theme-link3 {
  background-color: #FFEEE4; /* Pastel orange clair */
}

.theme-link4 {
  background-color: #E9FFE7; /* Pastel vert clair */
}

.theme-link5 {
  background-color: #FFE7F1; /* Pastel rose clair */
}

.theme-link6 {
  background-color: #FFF5E1; /* Pastel jaune clair */
}

/* Conteneur pour les rectangles */
.theme-links-container {
  display: flex;
  flex-wrap: wrap; /* Permet aux rectangles de passer à la ligne si nécessaire */
  justify-content: center; /* Centre les rectangles horizontalement */
  overflow-x: auto; /* Ajoute une barre de défilement horizontale si nécessaire */
  padding: 10px 0; /* Ajoute un peu de padding en haut et en bas */
}

.centered-text {
  text-align: center;
}

.centered-text p {
  font-size: 1.1em; /* Augmenter légèrement la taille de la police */
}

.centered {
  text-align: center;
}

/* Icône téléphone */
.phone-button::before {
  content: "\260E"; /* Unicode pour l'icône téléphone */
  margin-right: 5px; /* Ajoute un espace entre l'icône et le texte */
}

.phone-button {
  background: linear-gradient(45deg, #FBECD4, #D0BA98);
  color: #5c3525;
  border: none;
  padding: 9px 13px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, font-size 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  margin: 0 auto; /* Centre le bouton horizontalement */
}

.phone-button:hover {
  background: linear-gradient(45deg, #D0BA98, #FBECD4);
  transform: scale(1.02);
  font-size: 18px;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}

/* Styles pour le bouton "Prendre rendez-vous" */
.button,
.button-appointment {
  background: linear-gradient(45deg, #FBECD4, #D0BA98); /* Dégradé de couleur */
  color: #5c3525; /* Couleur du texte */
  border: none; /* Pas de bordure */
  padding: 9px 13px; /* Espacement intérieur */
  border-radius: 5px; /* Coins arrondis */
  font-size: 18px; /* Taille du texte */
  font-weight: bold; /* Texte en gras */
  cursor: pointer; /* Curseur pointeur */
  transition: background-color 0.3s ease, transform 0.3s ease, font-size 0.3s ease; /* Animations */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Ombre légère */
}

.button-appointment:hover {
  background: linear-gradient(45deg, #D0BA98, #FBECD4); /* Changement de couleur au survol */
  transform: scale(1.02); /* Légère augmentation au survol */
  font-size: 18px; /* Ajustement de la taille du texte au survol */
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2); /* Ombre plus prononcée au survol */
}

.button-container {
  text-align: center; /* Centrage horizontal du contenu */
  margin-top: 20px; /* Ajout d'une marge au-dessus du bouton */
}

/* Animation pulsation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.01); /* Ajustement de l'amplitude de la pulsation */
  }
  100% {
    transform: scale(1);
  }
}

.button-appointment:hover:not(.navbar) {
  animation: pulse 2s infinite; /* Animation pulsation au survol */
}

/* Styles spécifiques aux boutons dans la barre de navigation */
.navbar .button-appointment {
  padding: 5px 10px; /* Ajustement de la taille pour la barre de navigation */
  font-size: 14px; /* Ajustement de la taille du texte pour la barre de navigation */
}

.intro {
  font-size: 18px;
  color: #724429;
  text-align: left;
}

.intro2 {
  font-size: 15px;
  color: #724429;
  text-align: left;
}

.h4 {
  text-align: center;
}

.phone-button {
  display: block;
  margin: 0 auto 20px;
  padding: 10px 20px;
  color: #5e3521;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
.btn-voir-plus {
    background: linear-gradient(45deg, #FBECD4, #D0BA98); /* Dégradé de couleurs */
    color: #5c3525; /* Couleur du texte */
    border: none; /* Pas de bordure */
    padding: 10px 20px; /* Espacement intérieur */
    border-radius: 5px; /* Coins arrondis */
    font-size: 16px; /* Taille du texte */
    font-weight: bold; /* Texte en gras */
    cursor: pointer; /* Curseur pointeur */
    transition: background-color 0.3s ease, transform 0.3s ease, font-size 0.3s ease; /* Transition fluide */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Ombre */
    display: flex; /* Permet d'aligner le contenu */
    align-items: center; /* Alignement vertical */
    justify-content: center; /* Alignement horizontal */
    margin: 20px auto; /* Ajout d'espace autour du bouton */
  }
  
  .btn-voir-plus:hover {
    background-color: #e2c8a2; /* Changement de couleur au survol */
  }
  
  .btn-voir-plus:hover .icon {
    transform: rotate(180deg); /* Rotation de l'icône au survol */
  }
/* Styles spécifiques pour les champs d'application */
h2 {
    color: #724429; /* Couleur du titre */
    font-size: 24px; /* Taille de la police du titre */
    margin-bottom: 10px; /* Marge en bas du titre */
    text-align: center; /* Centrage du titre */
  }
  

  
  ul {
    list-style-type: none; /* Supprimer les puces de la liste */
    padding-left: 0; /* Supprimer le décalage à gauche de la liste */
  }
  
  .consultation-card {
    background-color: #FFFFFF; /* Couleur de fond de la carte de consultation */
    border-radius: 10px; /* Bordure arrondie de la carte */
    padding: 20px; /* Espacement interne de la carte */
    margin-bottom: 20px; /* Marge en bas de la carte */
    text-align: left; /* Alignement du contenu à gauche */
  }
  
  .consultation-card h2 {
    color: #724429; /* Couleur du titre de la carte */
    font-size: 20px; /* Taille de la police du titre de la carte */
    margin-bottom: 10px; /* Marge en bas du titre de la carte */
    text-align: center; /* Centrage du titre */
  }
  
  .consultation-card ul li {
    font-size: 16px; /* Taille de la police des éléments de la liste */
    margin-bottom: 5px; /* Marge en bas de chaque élément de la liste */
    text-align: left; /* Alignement du texte à gauche */
  }
  
  .consultation-card ul li strong {
    font-weight: bold; /* Mettre en gras le texte fort dans la liste */
  }
  

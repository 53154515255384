/* Styles généraux */
body {
  font-family: Arial, sans-serif;
  background-color: #d0ba98;
  margin: 0;
  padding: 0;
  max-width: 900px;
  margin: 0 auto;
}

.section {
  background-color: #ffffff;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: justify;
  margin-bottom: 20px;
}

/* Styles spécifiques à la barre de navigation */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  background-color: #fbecd4;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

/* Taille de police spécifique pour la barre de navigation */
.navbar button {
  background-color: transparent;
  border: none;
  color: #724429;
  font-weight: bold;
  cursor: pointer;
  font-size: 15px;
}

.navbar img {
  height: 40px;
}

/* Styles spécifiques aux titres */
h1, h2, h3 {
  color: #724429;
  text-align: center;
  margin-bottom: 20px;
}

.h4 {
  font-size: 18px;
  justify-content: space-between;
  color: #724429;
  text-align: justify;
  max-width: 600px;
  margin: 0 auto 20px;
  line-height: 1.5;
  overflow-wrap: break-word;
  padding: 20px;
}

.h4:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
}

/* Styles spécifiques aux paragraphes */
.section p {
  margin: 0 auto;
  max-width: 800px;
}

/* Styles spécifiques aux titres */
h1 {
  font-size: 26px;
  font-weight: bold;
  position: relative;
}

h1::after {
  content: "";
  display: block;
  width: 40%;
  height: 1px;
  background-color: #724429;
  position: absolute;
  bottom: -8px;
  left: 30%;
}

h2 {
  font-size: 24px;
  font-weight: bold;
}

h3 {
  font-size: 20px;
  font-weight: bold;
}

.h33 {
  color: black;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3vh; /* Ensure this element takes up full viewport height */
}


/* Soulignement pour les titres de niveau h2 et h3 */
h2::after, h3::after {
  content: "";
  display: block;
  width: 30%;
  height: 1px;
  background-color: #724429;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
}

/* Media query pour les écrans plus petits */
@media screen and (max-width: 768px) {
  .theme-links-container {
    display: block;
    text-align: center;
  }

  .theme-link1,
  .theme-link2,
  .theme-link3,
  .theme-link4,
  .theme-link5,
  .theme-link6 {
    display: block;
    margin: 5px auto;
    width: calc(100% - 30px);
  }
}

.phone-button:hover:not(.navbar) {
  animation: pulse 2s infinite;
}

.round-button {
  background-color: #724429;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  position: fixed;
  bottom: 60px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.dropdown {
  position: fixed;
  bottom: 80px;
  right: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: none;
  z-index: 999;
}

.round-button:hover + .dropdown,
.dropdown:hover {
  display: block;
}

.dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown li {
  margin-bottom: 10px;
}

.dropdown li a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  transition: color 0.3s;
}

.dropdown li a:hover {
  color: #724429;
  font-weight: bold;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .section {
    margin: 10px 0;
  }

  .field-card {
    margin: 10px 0;
    padding: 5px;
  }

  hr {
    margin: 10px 0;
  }
}

/* Media query pour les écrans de petite taille (mobile) */
@media screen and (max-width: 768px) {
  .navbar {
    flex-direction: column;
    padding: 10px;
  }

  .navbar img {
    margin-bottom: 10px;
  }

  .navbar button {
    display: none;
  }

  .image-container,
  .image-container.small {
    max-width: 100%;
    height: auto;
  }

  .team-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .team-member-card {
    width: 90%;
    margin: 10px;
  }
}

/* Ajout de marge en haut de la première section sur les appareils mobiles uniquement */
.section:first-child {
  margin-top: 100px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: left;
  z-index: 1001;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: left;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.modal-title {
  margin: 0;
}

.modal-close {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

h3, h4 {
  color: #333;
}

h4 {
  margin-top: 20px;
}

p {
  color: #555;
  line-height: 1.6;
  margin-bottom: 15px;
}

ul {
  padding-left: 20px;
}

ul li {
  margin-bottom: 10px;
}

ol {
  padding-left: 20px;
}

ol li {
  margin-bottom: 5px;
}
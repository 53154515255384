
/* Style pour le contenu tarifaire */
.tariff-content {
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .tariff-details p {
    margin-bottom: 25px;
    font-size: 16px;
  }
  
  .tariff-details p:first-child {
    font-size: 1.4rem;
    color: #724429;
    margin-bottom: 30px;
  }
  
  .tariff-details p:last-child {
    font-weight: bold;
    color: #333;
    text-align: center;  
    hyphens: auto; /* Empêcher les mots coupés */
  }
  
/* Style pour les conseils tarifaires */
.tariff-tips {
    background-color: #fff;
    border-radius: 15px;
    padding: 40px;
    margin-top: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .tariff-tips-content {
    color: #333;
    font-size: 16px;
    line-height: 1.6;
  }
  
  .tariff-tips-content ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .tariff-tips-content ul li {
    margin-bottom: 15px;
  }
  
  .disclaimer {
    margin-top: 30px;
  }
  
  .disclaimer p {
    color: #666;
    font-style: italic;
  }
    


  .tariff-details p strong {
    font-weight: bold;
  }
  
  
  
  .tariff-content {
    text-align: center;
    margin: 0 auto;

  }
  
  .tariff-details p {
    margin-bottom: 10px;
  }

  .image-container2 {
    width: 80%; /* Augmenter la largeur à 150% pour agrandir l'image */
    height: auto; /* Permettre à la hauteur de s'ajuster automatiquement pour maintenir les proportions */
  }
  
  

/* Style des cartes */
.field-card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
    transition: box-shadow 0.3s ease;
  }
  
  /* Style des titres des cartes */
  .field-card h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  /* Style des listes à l'arrière des cartes */
  .field-card ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .field-card ul li {
    font-size: 16px;
    margin-bottom: 8px;
  }
  
  /* Styles pour les balises h5 */
p.h5 {
  font-size: 15px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 10px;
  text-align: center;
}

  
  
  
  
  
  
  
  
  .consultation-card {
    background: linear-gradient(to bottom, #f8f8f0, #eae6da);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    position: relative;
    overflow: hidden;
  }
  
  .consultation-card:before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(to bottom right, #f8f8f0, #eae6da);
    border-radius: 50%;
    z-index: -1;
    transition: transform 0.5s ease;
    animation: rotate 10s linear infinite;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .consultation-card:hover {
    transform: translateY(-5px) scale(1.05);
  }
  
  .consultation-card h2 {
    color: #724429;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .consultation-card p {
    color: #000000;
    font-size: 1rem;
    line-height: 1.4;
  }
  
/* Styles spécifiques à la section contact */
.section-title {
  text-align: center;
}

.consultation-card {
  text-align: center;
}

.phone-button {
  display: block;
  margin: 0 auto 20px;
  padding: 10px 20px;
  color: #5e3521;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}



/* Ajustement du champ de message */
.form-container textarea#message {
  height: 120px; /* Hauteur de 3 lignes */
  resize: vertical; /* Permet à l'utilisateur de redimensionner la hauteur verticalement */
  width: 600px; /* Réduction de la largeur pour tenir compte de la bordure */
  padding: 8px; /* Espacement intérieur */
}
/* Ajustement du champ de message pour les formats mobiles */
@media screen and (max-width: 600px) {
  .form-container textarea#message {
    width: calc(100% - 16px); /* Calcul de la largeur en soustrayant les marges */
    padding: 8px; /* Conserver l'espacement intérieur */
  }
}


.phone-button:hover {
  background-color: #5e3521;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin-bottom: 10px;
}

.form-group {
  flex: 1;
  margin: 0 5px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type="submit"] {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #724429;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button[type="submit"]:hover {
  background-color: #5e3521;
}

/* Styles spécifiques au formulaire de contact */
.form-container {
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
}

.form-container label {
  font-weight: bold;
}

.form-container input[type="text"],
.form-container input[type="email"],
.form-container input[type="tel"],
.form-container select,
.form-container textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.form-container textarea {
  height: 150px;
}

.form-container button[type="submit"] {
  background-color: #724429;
  color: #FFFFFF;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-container button[type="submit"]:hover {
  background-color: #5c3525;
}

/* Styles spécifiques aux petits écrans */
@media screen and (max-width: 600px) {
  .form-row {
    flex-direction: column; /* Disposer les éléments verticalement */
  }

  .form-group {
    width: 100%; /* Utiliser toute la largeur disponible */
    margin-bottom: 10px; /* Ajouter une marge inférieure pour créer un espace entre les champs */
  }

  /* Supprimer la marge inférieure pour tous les champs */
  .form-group {
    margin-bottom: 10px;
  }
}


.h3 {
  font-size: 18px;
  justify-content: space-between;
  color: #724429;
  text-align: center; /* Modification de la valeur de la propriété 'text-align' */
  max-width: 600px;
  margin: 0 auto 20px;
  line-height: 1.5;
  overflow-wrap: break-word;
  padding: 20px;
}




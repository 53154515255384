/* Styles spécifiques à la section équipe */
.team-section {
  display: flex;
  justify-content: center; /* Centrer horizontalement les éléments */
  align-items: center; /* Centrer verticalement les éléments */
  text-align: center; /* Alignement horizontal des éléments au centre */
  
}

.team-member {
  margin: 10px;
}

.team-member-card {
  font-size: 13px; /* Taille de police plus petite pour la carte */
  background-color: #FFFFFF;
  border: 2px solid #724429;
  border-radius: 10px;
  padding: 10px;
  width: 200px; /* Largeur de la carte */
  height: 300px; /* Hauteur fixe pour toutes les cartes */
  text-align: justify; /* Justifier le texte dans les cartes */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* S'assurer que les éléments sont bien répartis */
  line-height: 1.4; /* Ajustement de l'espacement entre les lignes */
}



.team-member-card p {
  margin: 5px 0; /* Ajouter un espacement vertical minimal */
  letter-spacing: -0.5px; /* Réduire l'espacement entre les lettres */
  padding: 0; /* Retirer tout padding */
}

.team-member-card:hover {
  transform: translateY(-5px); /* Effet de translation lors du survol */
}

.team-member-photo {
  width: 100px; /* Largeur de la photo */
  height: 100px; /* Hauteur de la photo */
  background-color: #D0BA98; /* Couleur de fond de la photo */
  border-radius: 50%; /* Pour arrondir la photo */
  margin: 0 auto 10px; /* Espacement sous la photo */
}

.team-member-description {
  margin: 5px 0; /* Ajouter un espacement vertical minimal */
}

.team-member-card h2 {
  margin: 5px 0; /* Ajouter un espacement vertical minimal */
}

.team-member-card p {
  margin: 0; /* Réinitialiser la marge */
  padding: 0; /* Réinitialiser le padding */
  height: 100%; /* Prendre toute la hauteur disponible */
}

.team-member-card p.team-member-description {
  margin-top: auto; /* Pousse la description vers le bas */
}

.h46 {
text-align: justify;
}